import moment from "moment";

export const state = () => ({
  firstStep: {
    provider: null,
    state: null,
    service: null,
    date: moment().format("L"),
    time: {
      weekdays: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
      from: "7 AM",
      to: "5 PM",
    },
  },
  secondStep: {
    timestamps: [],
    selectedTimestamp: null,
    pagination: {
      allPages: 0,
      currentPage: 0,
    },
  },
  thirdStep: {
    validations: {
      hasErrors: true,
      emailError: true,

      phoneError: true,
    },
    inputs: {
      first_name: null,
      last_name: null,
      email: null,
      confirm_email: null,
      phone: null,
      confirm_phone: null,
      inputs: [],
    },
  },
  fourthStep: {
    stripeRef: null,
  },

  couponData: null,
});

function splitArrayIntoChunks(array, chunkSize) {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    let chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

function splitNestedArrays(originalArray, firstChunkSize, secondChunkSize) {
  let firstLevelChunks = splitArrayIntoChunks(originalArray, 70);

  let result = firstLevelChunks.map((chunk) => splitArrayIntoChunks(chunk, 10));

  return result;
}

const regexEmail =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const checkForPhoneErrors = (phone, confirm) => {
  const formatIsCorrect =
    phone && phone.length === 12 && confirm && confirm.length === 12;
  const phoneIsConfirmed = phone === confirm;
  if (formatIsCorrect && phoneIsConfirmed) return false;
  return true;
};

const checkForEmailErrors = (email, confirm) => {
  const formatIsCorrect = regexEmail.test(email) && regexEmail.test(confirm);
  const emailIsConfirmed = email === confirm;
  if (formatIsCorrect && emailIsConfirmed) return false;
  return true;
};

const checkForInputErrors = (inputs, firstName, lastName) => {
  if (!firstName || !firstName.length) return true;

  if (!lastName || !lastName.length) return true;

  if (!inputs.length) return true;

  const requiredFields = inputs.filter((e) => {
    return e.display && e.required;
  });

  const thereAreIncompleteFields = requiredFields.some((e) => {
    return !e.value || !e.value.length;
  });

  return thereAreIncompleteFields;
};

export const mutations = {
  SET_FIRST_STEP_DATA(state, data) {
    state.firstStep[data.point] = data.data;
  },
  SET_TIMESTAMP_PAGE_CHENGE(state, data) {
    state.secondStep.pagination.currentPage =
      data === "increase"
        ? state.secondStep.pagination.currentPage + 1
        : state.secondStep.pagination.currentPage - 1;
  },
  SET_TIMESTAMP(state, data) {
    state.secondStep.selectedTimestamp = data;
  },
  TIMESTAMP_SETTER(state, data) {
    let allTimeSlots = [];
    let selectedTimeslots = data[Object.keys(data)[0]];

    state.firstStep.provider = { id: Object.keys(data)[0] };

    for (const key in selectedTimeslots) {
      const element = selectedTimeslots[key];
      allTimeSlots.push({
        end: null,
        start: null,
        date: key,
        fullTimeFormat: key,
        disabled: true,
        is_date: true,
      });
      let mutatedElement = element.map((e) => {
        return {
          ...e,
          date: key,
          fullTimeFormat: `${e.start} ${e.end} ${key}`,
          disabled: true,
          is_date: false,
        };
      });
      allTimeSlots.push(mutatedElement);
    }

    let flattenedTimeSlots = allTimeSlots.flat(1);
    let totalAmmountToGenerate;
    let totalColumnsToGenerate;

    if (window.innerWidth <= 500) {
      totalAmmountToGenerate = 10;
      totalColumnsToGenerate = 1;
    } else if (window.innerWidth > 500 && window.innerWidth <= 700) {
      totalAmmountToGenerate = 20;
      totalColumnsToGenerate = 2;
    } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
      totalAmmountToGenerate = 30;
      totalColumnsToGenerate = 3;
    } else if (window.innerWidth > 900 && window.innerWidth <= 1100) {
      totalAmmountToGenerate = 40;
      totalColumnsToGenerate = 4;
    } else {
      totalAmmountToGenerate = 60;
      totalColumnsToGenerate = 5;
    }

    let paginatedTimeSlots = splitArrayIntoChunks(
      flattenedTimeSlots,
      totalAmmountToGenerate
    );

    let mutatedPaginatedTimeslots = paginatedTimeSlots.map((chunk) =>
      splitNestedArrays(chunk, 10, totalColumnsToGenerate)
    );

    state.secondStep.timestamps = mutatedPaginatedTimeslots;
    state.secondStep.pagination.allPages = mutatedPaginatedTimeslots.length;
  },
  SET_THIRD_STEP_DATA(state, data) {
    state.thirdStep["inputs"][data.point] = data.data;
    const firstName = state.thirdStep["inputs"]["first_name"];
    const lastName = state.thirdStep["inputs"]["last_name"];
    const email = state.thirdStep["inputs"]["email"];
    const confirmEmail = state.thirdStep["inputs"]["confirm_email"];
    const phone = state.thirdStep["inputs"]["phone"];
    const confirmPhone = state.thirdStep["inputs"]["confirm_phone"];
    if (data.point === "email" || data.point === "confirm_email") {
      state.thirdStep["validations"]["emailError"] = checkForEmailErrors(
        email,
        confirmEmail
      );
    }
    if (data.point.includes("phone")) {
      state.thirdStep["validations"]["phoneError"] = checkForPhoneErrors(
        phone,
        confirmPhone
      );
    }
    state.thirdStep["validations"]["hasErrors"] = checkForInputErrors(
      state.thirdStep["inputs"]["inputs"],
      firstName,
      lastName
    );
  },
  SET_COUPON_DATA(state, data) {
    state.couponData = data;
  },
  SET_THIRD_STEP_INPUTS(state, data) {
    state.thirdStep["inputs"]["inputs"] = [];
    state.thirdStep["inputs"]["inputs"] = data.data;
    const firstName = state.thirdStep["inputs"]["first_name"];
    const lastName = state.thirdStep["inputs"]["last_name"];

    state.thirdStep["validations"]["hasErrors"] = checkForInputErrors(
      state.thirdStep["inputs"]["inputs"],
      firstName,
      lastName
    );
  },
  SET_STRIPE_REF(state, data) {
    if (state.fourthStep.stripeRef) return;
    state.fourthStep.stripeRef = data;
  },
};

export const actions = {
  setInputs({ commit }, inputs) {
    commit("SET_INPUTS", inputs);
  },
  updateStripeElementCard({ commit }, card) {
    commit("SET_STRIPE_REF", card);
  },
};

export const getters = {
  getFirstSteps: (state) => () => {
    return state.firstStep;
  },
  getSecondSteps: (state) => () => {
    return state.secondStep;
  },
  getThirdSteps: (state) => () => {
    return state.thirdStep;
  },
  getFourthSteps: (state) => () => {
    return state.fourthStep;
  },
  getCouponData: (state) => () => {
    return state.couponData;
  },
};
