//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import "vue-multiselect/dist/vue-multiselect.min.css";
import moment from "moment";
export default {
  props: {
    activeNote: Object,
  },
  validations() {
    return {
      form: {
        date: {
          required: requiredIf(() => {
            return this.activeNote.type !== "internal";
          }),
        },
        minutes: {
          required: requiredIf(() => {
            return this.activeNote.type !== "internal";
          }),
        },
        seconds: {
          required: requiredIf(() => {
            return this.activeNote.type !== "internal";
          }),
        },
        call_type: {
          required: requiredIf(() => {
            return this.activeNote.type !== "internal";
          }),
        },
        call_status: {
          required: requiredIf(() => {
            return this.activeNote.type !== "internal";
          }),
        },
        follow_up_actions: {
          required: requiredIf(() => {
            return (
              this.form.call_status &&
              this.form.call_status.includes("Success") &&
              this.activeNote.type !== "ccm" &&
              this.activeNote.type !== "internal"
            );
          }),
        },
        patient_status: {
          required: requiredIf(() => {
            return (
              this.form.call_status &&
              this.form.call_status.includes("Success") &&
              this.activeNote.type !== "ccm" &&
              this.activeNote.type !== "internal"
            );
          }),
        },
        categories_reviewed: {
          required: requiredIf(() => {
            return (
              this.form.call_status &&
              this.form.call_status.includes("Success") &&
              this.activeNote.type !== "ccm" &&
              this.activeNote.type !== "internal"
            );
          }),
        },
        icd_codes: {
          required: requiredIf(() => {
            if (
              this.activeNote.type !== "internal" &&
              this.form.call_status &&
              this.form.call_status.includes("Success")
            ) {
              return this.icdLimits() > 0;
            }
            return false;
          }),
          minLength: this.icdLimitsMinLength(),
        },
      },
      message: { required },
    };
  },
  data() {
    return {
      ignoreWatchers: false,
      message: "",
      returnTo: null,
      errorQuestions: false,
      hasErrors: false,
      errorStyle: {
        border: "1px solid red !important",
        boxSizing: " border-box !important",
        borderRadius: "10px",
      },
      submitted: false,
      form: {
        type: null,
        call_status: null,
        call_type: "Phone Call",
        minutes: "00",
        seconds: "00",
        date: new Date(),
        patient_status: null,
        categories_reviewed: [],
        follow_up_actions: null,
        icd_codes: [],
        description: null,
      },
      noteIndexes: [],
      rtmVitalsOptions: ["Pain Score", "Stretching", "Medications"],
      vitals: [
        "Blood Pressure",
        "Heart Rate",
        "Glucose",
        "Weight",
        "Oxygen Saturation",
      ],
      questions: [
        {
          question:
            "Are you experiencing any of the critical signs and symptoms including but not limited to Feeling weak, Unsteady, or dizzy, Fever or other signs/symptoms of infection, Significant change in urinary output (increase or decrease), Shortness of Breath:",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Since your last care coordination call, have you had a Physician Visit?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Since your last care coordination call, have you had any changes in medications?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question: "Do you have any questions about your medications?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Have you been taking your medications exactly as prescribed?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Since your last care coordination call, have you had a fall?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Since your last care coordination call, have you had an Emergency Room Visit?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Since your last care coordination call, have you had a Hospital stay?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question:
            "Have you experienced any symptoms or issues that concerned you since our last call",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question: "Are you exercising 30 minutes a day?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question: "Are you following any specific diet?",
          answers: ["Yes", "No"],
          answer: null,
        },
        {
          question: "Are you monitoring your BP/BG/ Weight?",
          answers: ["Yes", "No"],
          answer: null,
        },
      ],
      callStatusOptions: [
        "Success",
        "Partial Success",
        "Not Started",
        "Declined",
        "Unable to connect",
      ],
      durationOptionsSeconds: [],
      durationOptions: [],
    };
  },
  methods: {
    icdLimitsMinLength() {
      return minLength(this.icdLimits());
    },
    icdLimits() {
      let ccmMinLength = this.$vianovaConfig.options.icd_codes
        ? this.$vianovaConfig.options.icd_codes.rules.ccm
        : 0;
      let rpmMinLength = this.$vianovaConfig.options.icd_codes
        ? this.$vianovaConfig.options.icd_codes.rules.rpm
        : 0;
      let rtmMinLength = this.$vianovaConfig.options.icd_codes
        ? this.$vianovaConfig.options.icd_codes.rules.rtm
        : 0;
      let minLength =
        this.activeNote.type === "rpm"
          ? rpmMinLength
          : this.activeNote.type === "rtm"
          ? rtmMinLength
          : this.activeNote.type === "ccm"
          ? ccmMinLength
          : 0;
      return minLength;
    },
    async addNote() {
      this.errorQuestions = false;
      this.submitted = true;
      this.hasErrors = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasErrors = true;
      }
      if (
        this.activeNote.type === "ccm" &&
        this.form.call_status &&
        this.form.call_status.includes("Success") &&
        this.questions.some((question) => !question.answer)
      ) {
        this.errorQuestions = true;
      }
      if (
        (this.activeNote.type === "ccm" &&
          this.form.call_status &&
          this.form.call_status.includes("Success") &&
          this.questions.some((question) => !question.answer)) ||
        this.$v.$invalid
      ) {
        this.submitted = false;
        return;
      }

      let organizationId = localStorage.getItem("organizationId");

      let eventTime = moment(this.selectedDate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      let assessmentPayload = {
        activity_type_slug: `ccm_assessment`,
        response: {
          app: {
            version: this.$connect.version,
            app: "connect-web",
            build: window.location.hostname,
          },
          questions: this.questions,
          value: `Task Done`,
          created_at: eventTime,
          before_meal: false,
          after_meal: false,
          with_meal: true,
          notes: null,
          unit: null,
          method: "Manually",
        },
        created_at: eventTime,
        time_type: "AM",
      };

      this.form.duration = `${this.form.minutes}:${this.form.seconds}`;

      let payload = {
        data: {
          form: { ...this.form },
          questions: this.questions,
        },
        title: "New Encounter",
        description: this.message,
        coordinator: "",
        note: "New Encounter",
        // minutes: this.form.duration ? this.form.duration.substring(0, 2) : 0,
        // seconds: this.form.duration ? this.form.duration.substring(3, 5) : 0,

        minutes: this.form.minutes,
        seconds: this.form.seconds,

        type: this.activeNote.type.toLowerCase(),
        call_status: this.form.call_status,
        created_at: moment(this.form.date).format("YYYY-MM-DD HH:mm:ss"),
        activity_responses:
          this.activeNote.type === "ccm" &&
          ["Success", "Partial Success"].includes(this.form.call_status)
            ? [
                {
                  activity_type_slug: `ccm_assessment`,
                  response: {
                    app: {
                      version: this.$connect.version,
                      app: "connect-web",
                      build: window.location.hostname,
                    },
                    questions: this.questions,
                    value: `Task Done`,
                    created_at: eventTime,
                    before_meal: false,
                    after_meal: false,
                    with_meal: true,
                    notes: null,
                    unit: null,
                    method: "Manually",
                  },
                  created_at: eventTime,
                  time_type: "AM",
                },
              ]
            : [],
      };
      if (this.activeNote.mode === "create") {
        await this.$axios
          .post(
            `${organizationId}/patients/${this.patient.id}/care_notes`,
            payload
          )
          .then((res) => {
            this.submitted = false;
            Swal.fire({
              position: "center",
              icon: "success",
              title: "New Note Added Successfully",
              timer: 2000,
              showConfirmButton: true,
            });
            if (this.$route.name.includes("patient")) {
              this.$store.dispatch("modules/am_patient_filters/setAddedNote", {
                note: res.data,
              });
            }
            if (
              this.$route.name === "overview" ||
              this.$route.name === "users-id-timers" ||
              this.$route.name.includes("overview") ||
              this.$route.name === "users-id-appointments" ||
              this.$route.name.includes("communications") ||
              this.$route.name === "users-id-alerts"
            ) {
              this.$store.commit(
                "modules/patient-notes/SET_RECENTLY_ADDED_NOTE",
                true
              );
            }
            this.$store.dispatch("assessments/fetchAssessments", {
              patient_id: this.patient.id,
              organization_id: organizationId,
            });
            this.$store.commit(
              "modules/patient-notes/ADD_NOTE_TO_LIST",
              res.data
            );
            this.closeModal();
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "info",
              title: "Failed Adding New Encounter",
              showConfirmButton: true,
            });
            this.submitted = false;
          });
      } else {
        if (this.activeNote.ccm_assessment_id) {
          await this.$store.dispatch("assessments/updateAssessment", {
            patient_id: this.activeNote.patientId,
            organization_id: localStorage.getItem("organizationId"),
            payload: assessmentPayload,
            id: this.activeNote.ccm_assessment_id,
          });
        }
        await this.$axios
          .patch(
            `${localStorage.getItem("organizationId")}/patients/${
              this.patient.id
            }/care_notes/${this.activeNote.noteId}`,
            payload
          )
          .then((res) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Note Updated Successfully",
              timer: 1500,
              showConfirmButton: true,
            });
            if (this.$route.name === "overview") {
              this.$store.commit(
                "modules/patient-notes/SET_RECENTLY_ADDED_NOTE",
                true
              );
            }
            this.$store.dispatch("assessments/fetchAssessments", {
              patient_id: this.activeNote.patientId,
              organization_id: organizationId,
            });
            this.closeModal();
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "info",
              timer: 1500,
              title: "Failed Updating Note",
              showConfirmButton: true,
            });
            this.submitted = false;
          });
      }
    },
    editPatient() {
      this.$bvModal.show("editPatient");
    },
    patientUpdatedCallback() {
      this.fetchPatient();
    },
    async fetchPatient() {
      await this.$store.dispatch("patient/getPatientDetails", {
        organization_id: localStorage.getItem("organizationId"),
        id: this.activeNote.patientId,
      });
    },
    firstNoteIndex() {
      let noteText;
      this.$nextTick(() => {
        if (
          !this.form.call_status ||
          !this.form.minutes ||
          !this.form.seconds ||
          !this.form.call_type ||
          !this.form.date
        ) {
          if (this.noteIndexes.length)
            this.message = this.noteIndexes.join(" ");
          return;
        }
        if (this.form.call_status.includes("Success")) {
          noteText = `On ${moment(this.form.date).format(
            "MM/DD/YYYY hh:mm A"
          )}, an ${this.activeNote.type.toUpperCase()} note was generated for the patient. The method of contact was a ${
            this.form.call_type
          }, and the call lasted for ${this.duration}  ${
            this.duration === "01:00" ? "minute" : "minutes"
          }.`;
        }
        if (this.form.call_status === "Not Started") {
          noteText = `On ${moment(this.form.date).format(
            "MM/DD/YYYY hh:mm A"
          )}, an ${this.activeNote.type.toUpperCase()} note was generated for the patient. ${
            this.activeNote.type.toUpperCase() !== "RPM"
              ? "This note took"
              : `The method of contact was a ${this.form.call_type}, and the call lasted for`
          } ${this.duration} ${
            this.duration === "01:00" ? "minute" : "minutes."
          }.`;
        }
        if (
          this.form.call_status === "Declined" ||
          this.form.call_status === "Unable to connect"
        ) {
          noteText = `On ${moment(this.form.date).format(
            "MM/DD/YYYY hh:mm A"
          )}, an ${this.activeNote.type.toUpperCase()} note was generated for the patient. This note took ${
            this.duration
          }  ${this.duration === "01:00" ? "minute" : "minutes"}.`;
        }
        this.noteIndexes[0] = noteText;
        if (this.ignoreWatchers) return;
        if (this.noteIndexes.length) this.message = this.noteIndexes.join(" ");
      });
      this.$forceUpdate();
    },
    secondNoteIndex() {
      this.$nextTick(() => {
        if (
          this.form.patient_status &&
          this.form.categories_reviewed &&
          this.form.categories_reviewed.length &&
          this.activeNote.type !== "ccm"
        ) {
          this.noteIndexes[1] = `During the call, the patient’s ${this.form.categories_reviewed.join(
            ", "
          )} were reviewed and the patient was determined to be ${
            this.form.patient_status
          }.`;
        } else {
          this.noteIndexes[1] = [];
        }
        if (this.ignoreWatchers) return;
        if (this.noteIndexes.length) this.message = this.noteIndexes.join(" ");
      });
    },
    thirdNoteIndex() {
      this.$nextTick(() => {
        let codes = this.form?.icd_codes.map((code) => code.code);
        if (codes.length && this.activeNote.type !== "ccm") {
          this.noteIndexes[2] = `The following ICD codes were also noted: ${codes.join(
            ", "
          )}.`;
        } else {
          this.noteIndexes[2] = [];
        }
        if (this.ignoreWatchers) return;
        if (this.noteIndexes.length) this.message = this.noteIndexes.join(" ");
      });
    },
    closeModal() {
      this.$store.commit("modules/patient-notes/SET_ACTIVE_NOTE", null);
    },
    fourthNoteIndex(event) {
      this.$nextTick(() => {
        if (!this.form.follow_up_actions) this.noteIndexes[3] = [];
        if (this.form.follow_up_actions)
          this.noteIndexes[3] = `As a follow-up, it has been decided to ${this.form.follow_up_actions} the patient.`;
        if (this.ignoreWatchers) return;
        if (this.noteIndexes.length) this.message = this.noteIndexes.join(" ");
      });
    },
    icdSelectorVisible(program) {
      if (program) {
        let programs = this.$vianovaConfig.options.icd_codes
          ? this.$vianovaConfig.options.icd_codes.programs
          : ["rpm"];
        return programs.includes(program.toLowerCase());
      }
      return false;
    },
  },
  mounted() {
    for (let i = 0; i < 60; i++) {
      this.durationOptions.push(
        moment().startOf("day").add(i, "minutes").format("mm")
      );
      this.durationOptionsSeconds.push(
        moment().startOf("day").add(i, "seconds").format("ss")
      );
    }

    // durationOptionsSeconds: [
    //     "00",
    //     "05",
    //     "10",
    //     "15",
    //     "20",
    //     "25",
    //     "30",
    //     "35",
    //     "40",
    //     "45",
    //     "50",
    //     "55",
    //   ],
    //   durationOptions: [

    this.fetchPatient();
    this.ignoreWatchers = true;
    if (this.activeNote.questions) {
      this.$nextTick(() => {
        for (let index = 0; index < this.activeNote.questions.length; index++) {
          const element = this.activeNote.questions[index];
          this.questions[index] = { ...element };
        }
      });
    }
    if (this.activeNote.message) {
      this.$nextTick(() => {
        this.message = this.activeNote.message;
      });
    }
    if (this.activeNote.form) {
      this.$nextTick(() => {
        this.form = {
          ...this.activeNote.form,
        };
        if (
          this.activeNote &&
          this.activeNote.form &&
          this.activeNote.form.duration
        ) {
          this.form.minutes = this.activeNote.form.duration.split(":")[0];
          this.form.seconds = this.activeNote.form.duration.split(":")[1];
        }
      });
    }

    if (!this.noteIndexes.length) {
      this.firstNoteIndex();
      this.secondNoteIndex();
      this.thirdNoteIndex();
      this.fourthNoteIndex();
    }
    setTimeout(() => {
      this.ignoreWatchers = false;
    }, 1000);
  },
  computed: {
    ...mapGetters("patient", {
      patient: "patient",
    }),
    vitalsOptions() {
      if (this.activeNote.type === "rtm") {
        return this.rtmVitalsOptions;
      }
      return this.vitals;
    },
    callStatus() {
      return this.form.call_status;
    },
    callType() {
      return this.form.call_type;
    },
    duration() {
      return `${this.form.minutes}:${this.form.seconds}`;
    },
    date() {
      return this.form.date;
    },
    patientStatus() {
      return this.form.patient_status;
    },
    categoriesReviewed() {
      return this.form.categories_reviewed;
    },
    followUpActions() {
      return this.form.follow_up_actions;
    },
    selectedICDCodes() {
      return this.form.icd_codes;
    },
    icdCodesSource() {
      if (
        this.$vianovaConfig.options.icd_codes &&
        this.$vianovaConfig.options.icd_codes.source === "patient"
      )
        return "patient";
      return "self";
    },
    ICDcodeOptions() {
      if (
        this.$vianovaConfig.options.icd_codes &&
        this.$vianovaConfig.options.icd_codes.source === "patient"
      ) {
        if (this.patient) {
          return this.patient.icdCodes ?? [];
        } else {
          return [];
        }
      }
      return [
        {
          display_name: "(E039) Hypothyroidism, unspecified",
          name: "Hypothyroidism, unspecified",
          code: "E039",
        },
        {
          display_name: "(E1065) Type 1 diabetes mellitus with hyperglycemia",
          name: "Type 1 diabetes mellitus with hyperglycemia",
          code: "E1065",
        },
        {
          display_name: "(E109) Type 1 diabetes mellitus without complications",
          name: "Type 1 diabetes mellitus without complications",
          code: "E109",
        },
        {
          display_name: "(E1165) Type 2 diabetes mellitus with hyperglycemia",
          name: "Type 2 diabetes mellitus with hyperglycemia",
          code: "E1165",
        },
        {
          display_name: "(E119) Type 2 diabetes mellitus without complications",
          name: "Type 2 diabetes mellitus without complications",
          code: "E119",
        },
        {
          display_name: "(I10) Essential (primary) hypertension",
          name: "Essential (primary) hypertension",
          code: "I10",
        },
      ];
    },
  },
  components: {
    Multiselect,
  },
};
